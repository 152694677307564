@import "mixins";
@import "variables";

html {
	font-size: $fontSizeBase + px; }

body {
	@include fontMain;
	// font-weight: 500
	font-weight: 400;
	color: $main;
	line-height: 1.4285 /*based on line height of paragraphs in typography page*/; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.mash-menu h1, .mash-menu h2, .mash-menu h3, .mash-menu h4, .mash-menu h5, .mash-menu h6 {
	margin-top: 0;
	margin-bottom: 0.5em /*may be changed in future*/;
	font-weight: 700;
	line-height: 1.46; }

.mash-menu {
	h1, h2, h3, h4, h5, h6 {
		color: inherit; } }

/*headings font-sizes based on tygraphy page*/
h1,
.mash-menu h1 {
	@include rem(24) /*24px*/; }

h2,
.mash-menu h2 {
	@include rem(20) /*20px*/; }

h3,
.mash-menu h3 {
	@include rem(18) /*18px*/; }

h4,
.mash-menu h4 {
	@include rem(16) /*16px*/; }

h5,
.mash-menu h5 {
	@include rem(14) /*14px*/; }

h6,
.mash-menu h6 {
	@include rem(12) /*12px*/; }

a {
	color: inherit;
	&:focus, &:hover {
		color: inherit; } }

.no-underline {
	@include hf {
		text-decoration: none; } }

mark {
	// background: transparent
 }	// color: $orange

.blue {
	color: $blue; }

.orange {
	color: $orange; }

.red {
	color: $red; }

.blue-dark {
	color: $blueDark; }

.violet {
	color: $violet; }

/*columns in programs*/
.bottom-offset {
	margin-bottom: 30px; }

.bottom-offset-2x {
	margin-bottom: 60px; }

.bottom-offset-1-2 {
	margin-bottom: 15px; }

.bottom-offset-1-5 {
	margin-bottom: 45px; }

.offset-y {
	margin-top: 30px;
	margin-bottom: 30px; }

/*sections: programs, events*/
.section-padding {
	padding-top: 21px; }

/*headings in events, news*/
.section-heading {
	margin-bottom: 17px; }

.form-control {
	padding: 13px 19px 14px 19px;
	border-radius: 0;
	&.no-border {
		border: none;
		// &:focus
 }		/// box-shadow: 0 0 0px 1px $violet


	// .has-success
	// 	color: $success
	// 	.form-control
	// 		border-color: $success

	// .has-danger
	// 	color: $danger
	// 	.form-control
	// 		border-color: $danger

	// .alert-danger
	// 	background-color: lighten($danger, 45%)
	// 	border-color: lighten($danger, 30%)
	// 	color: $danger

	// .alert-success
	// 	background-color: lighten($success, 37%)
	// 	border-color: lighten($success, 30%)
 }	// 	color: $success

/*based on subscribe form*/
.form-control-lg {
	@include rem(14);
	height: 47px; }

.nested-list {
	margin-left: 0;
	padding: 0;
	list-style: none;
	color: #b3b3b3;
	@include rem(18);
	ul {
		list-style: none;
		padding: 0;
		margin: 0; }
	a {
		display: block;
		// +hf
 }		// 	text-decoration: none
	> li {
		border-bottom: #ececec 1px solid;
		font-weight: 700;
		> a {
			padding: 13px 11px;
			.fa {
				margin-right: 14px; } }
		> ul {
			padding-top: 7px;
			padding-bottom: 23px;
			@include rem(16);
			padding-left: 20px;
			background-color: #ececec;
			> li {
				color: #898686;
				> a {
					padding: 7px 15px; }
				.fa {
					@include rem(12);
					margin-right: 4px; }
				> ul {
					padding-left: 30px;
					@include rem(14);
					font-weight: 400;
					> li {
						> a {
							padding: 5px 15px; }
						> ul {
							padding-left: 24px;
							margin-bottom: 10px;
							> li {
								> a {
									padding: 0px 15px; } } } } } } } } }


.text-opacity {
	opacity: 0.62; }

.heading-underline-icon {
	position: relative;
	padding-bottom: 10px;
	padding-left: 8px;
	margin-bottom: 22px;
	border-bottom: $orange 5px solid; }

.heading-underline-icon-red {
	border-color: $red; }

.heading-underline-icon-blue-dark {
	border-color: $blueDark; }

.heading-underline-icon-img {
	position: absolute;
	right: 11px;
	bottom: 0; }




/*BUTTONS START*/
/*--------------------------------------------------------------------------*/

.btn {
	font-size: 1rem;
	padding: 8px 23px;
	border-radius: 0;
	// +hf
 }	// &:active

/*btn-sm may be changed*/
.btn-group-sm>.btn,
.btn-sm {
	min-width: 118px;
	padding: 7px 15px; }

.btn-group-lg>.btn,
.btn-lg {
	min-width: 140px;
	padding: 13px 36px 14px 36px;
	text-transform: uppercase;
	font-weight: 700; }

.btn-violet {
	background-color: $violet;
	color: #fff;
	@include hf {
		color: #fff; } }

.btn-violet-outline {
	border-color: $violet;
	color: $violet;
	background-color: transparent;
	@include hf {
		color: #fff;
		background-color: $violet; } }


/*--------------------------------------------------------------------------*/
/*BUTTONS END*/











